<template>
	<v-container
		class="branch list p-3"
	>
		<div >
			<v-row>
				<v-col>
					<v-btn-toggle
						v-model="search.sortType"
						class="pa-0 "
						color="success2"
						
					>
						<v-btn
							value="JoinDt"
							small
							outlined
							@click="onClick('JoinDt')"
						>
							등록순
						</v-btn>
						<v-btn
							small
							value="Name"
							outlined
							@click="onClick('Name')"
						>
							이름순
						</v-btn>
					</v-btn-toggle>
				</v-col>
				<v-col
					cols="4"
				>
					<v-select 
						v-model="search.status"
						:items="status"
						item-text="codeName"
						item-value="code"
						label="상태"
						solo
						height="0"
						dense
						hide-details
						loader-height="1"
						class="text-caption"
						
						@change="onChange"
					></v-select>
				</v-col>
			</v-row>
		</div>
	
		<div
			class="pa-0 mt-3 mb-150"
		>
			<v-card
				v-for="(item, index) in list_normal"
				:key="index"
				elevation="0"
				
				class="pa-0 pb-2 ma-0 mb-2 border-bottom"
				tile
			>
				<v-list
					class="pa-0"
				>
					<v-list-item
						class="pa-0 ma-0"
					>
						<v-list-item-avatar
								class="pa-0 ma-0"
								size="60"
							>
							<v-btn
								v-model="item.status"
								v-if="edit"
								icon
								x-small
								@click="check(item)"
							>
								<v-icon
									v-if="!item.checked"
								>mdi-checkbox-blank-outline</v-icon>
								<v-icon
									v-if="item.checked"
									color="success2"
								>mdi-checkbox-marked</v-icon>
							</v-btn>
							<v-icon 
								v-if="!edit"
								large
								:color="item.status_color"
							>mdi-account-circle</v-icon>
						</v-list-item-avatar>
						<v-list-item-content
							class="pa-0 ma-0"
						>
							<div class="text-subtitle-1 font-weight-bold text-black">
								{{ item.salesManager }}
								<v-btn
									class="d-inline-block float-right rounded-pill "
									:color="item.statusColor"
									outlined
									x-small
								>{{ item.statusName }}</v-btn>
							</div>
							<div
								class="pt-0 pb-0 text-body-2"
							>
								{{ item.phoneNumber }}
								<span class="float-right">{{ item.joinDate | transDate }}</span>
							</div>
							<div
								class="pt-0 pb-0 text-caption text-gray"
							>
							</div>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-card>
			
			<v-card
				v-if="items.length <= 0"
			>
				<v-card-text
					class="text-center"
				>
					조회된 영업점이 없습니다.
				</v-card-text>
			</v-card>
			
			<v-btn
				fab
				dark
				fixed
				right
				color="success2"
				small
				:to="'/Branch/Choice'"
				style="bottom: 80px;"
			>
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</div>
	</v-container>
</template>

<script>
	export default{
		name: 'Branch'
		,created: function(){
			this.$emit('setProgram', this.program)
			this.getData()
		}
		,props: ['callBack', 'user']
		,data: function(){
			return{
				program: {
					title: '영업점 관리'
					,next: {
						title: '관리'
						,do: true
						,callBack: 'edit'
					}
				}
				,status: [
					{
						code: null
						,codeName: '대기'
						,codeColor: ''
					}
					,{
						code: 'Normal'
						,codeName: '사용중'
						,codeColor: 'yellow'
					}
					,{
						code: 'Pause'
						,codeName: '정지'
						,codeColor: 'grey'
					}
					,{
						code: 'Termination'
						,codeName: '종료'
						,codeColor: 'red'
					}
				]
				,search: {
					sortType: 'JoinDt'
					,status: 'Normal'
				}
				,edit: false
				,items: [
					
				]
				,sample: [
					{
						branchId: 1
						,salesManager: '영업 담당자1'
						,phoneNumber: '010-1234-5678'
						,status: 'Normal'
						,joinDate:  '20200814'
					}
					,{
						branchId: 2
						,salesManager: '영업 담당자2'
						,phoneNumber: '010-1234-5678'
						,status: 'Pause'
						,joinDate:  '20200814'
					}
					,{
						branchId: 3
						,salesManager: '영업 담당자3'
						,phoneNumber: '010-1234-5678'
						,status: 'Termination'
						,joinDate:  '20200814'
					}
					,{
						branchId: 4
						,salesManager: '영업 담당자4'
						,phoneNumber: '010-1234-5678'
						,status: 'Termination'
						,joinDate:  '20200814'
					}
					,{
						branchId: 5
						,salesManager: '영업 담당자5'
						,phoneNumber: '010-1234-5678'
						,status: 'Termination'
						,joinDate:  '20200814'
					}
					,{
						branchId: 4
						,salesManager: '영업 담당자4'
						,phoneNumber: '010-1234-5678'
						,status: 'Termination'
						,joinDate:  '20200814'
					}
					,{
						branchId: 5
						,salesManager: '영업 담당자5'
						,phoneNumber: '010-1234-5678'
						,status: 'Termination'
						,joinDate:  '20200814'
					}
				]
			}
		}
		,methods: {
			getData: function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/sales'
					,request_params: {
						sortType: this.search.sortType
						,status: this.search.status
					}
					,authorize: true
					,callBack: 'result'
				})
			}
			,result: function(call){
				this.items = call.items.content
//				this.items = this.sample
			}
			,check: function(call){
				if(call.checked){
					this.$set(call, 'checked', false)
				}else{
					this.$set(call, 'checked', true)
				}
			}
			,onChange: function(call){
				this.search.status = call
				this.getData()
			}
			,onClick: function(call){
				this.search.sortType = call
				this.getData()
			}
		}
		,computed: {
			list_normal: function(){
				let self = this
				return this.items.filter(function(item){
					for(let index in self.status){
						let tt = self.status[index]
						if(item.status == tt.code){
							item.statusName = tt.codeName
							item.statusColor = tt.codeColor
						}
					}
					
					return item
				})
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					console.log('watch branch')
					console.log(call)
					if(call.name == 'result'){
						this.result(call)
					}else if(call.name == 'edit'){
						if(this.edit){
							this.edit = false
							this.program.next.title = '관리'
						}else{
							this.edit = true
							this.program.next.title = '완료'
						}
					}
				}
			}
		}
	}
</script>

<style>
	.branch.list .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
		margin: 0;
		max-height: 32px;
		min-height: 30px !important;
		align-items: center!important
	}

	.branch.list .v-input__control input{
		max-height: 0px;
		min-height: 0px;
	}
	
	.branch.list .v-select__selection{
		min-height: 0px;
	}
	
	.branch.list .v-input__slot {
		padding: 0px 5px 0px 10px !important;
	}
	
	.branch.list .border-bottom:not(:last-child) {border-bottom: 1px solid #ddd;}
	
	.mb-150 {margin-bottom: 150px;}
</style>
